// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import $ from 'jquery';
import * as ko from 'knockout';
import huePubSub from 'utils/huePubSub';
import { getFromLocalStorage, setInLocalStorage } from 'utils/storageUtils';
ko.bindingHandlers.assistVerticalResizer = {
  init: function init(element, valueAccessor) {
    var $container = $(element);
    var options = ko.unwrap(valueAccessor());
    var panelDefinitions = options.panels;
    var checkForElements = function checkForElements() {
      var $allPanels = $container.children('.assist-inner-panel');
      var $allExtras = $container.children('.assist-fixed-height');
      if (panelDefinitions().length === $allPanels.length && ($allExtras.length > 0 || options.noFixedHeights)) {
        ko.bindingHandlers.assistVerticalResizer.updateWhenRendered(element, valueAccessor);
      } else {
        timeout = window.setTimeout(checkForElements, 10);
      }
    };
    checkForElements();
    var timeout = -1;
    panelDefinitions.subscribe(function () {
      window.clearTimeout(timeout);
      timeout = window.setTimeout(checkForElements, 10);
    });
  },
  updateWhenRendered: function updateWhenRendered(element, valueAccessor) {
    var options = ko.unwrap(valueAccessor());
    var panelDefinitions = options.panels;
    var $container = $(element);
    var $allPanels = $container.children('.assist-inner-panel');
    var $allResizers = $container.children('.assist-resizer');
    var $allExtras = $container.children('.assist-fixed-height');
    var allExtrasHeight = 0;
    $allExtras.each(function (idx, extra) {
      allExtrasHeight += $(extra).outerHeight(true);
    });
    window.clearInterval($container.data('height_interval'));
    if (panelDefinitions().length === 0) {
      $allExtras.show();
      return;
    }
    if (panelDefinitions().length === 1) {
      var adjustHeightSingle = function adjustHeightSingle() {
        $allPanels.height($container.innerHeight() - allExtrasHeight);
      };
      var heightAdjustInterval = window.setInterval(adjustHeightSingle, 800);
      adjustHeightSingle();
      $container.data('height_interval', heightAdjustInterval);
      $(window).resize(adjustHeightSingle);
      huePubSub.subscribe('assist.forceRender', function () {
        window.setTimeout(adjustHeightSingle, 200);
      });
      $allExtras.show();
      $allPanels.show();
      return;
    }
    var panelRatios = getFromLocalStorage('assist.innerPanelRatios', {});
    var totalRatios = 0;
    $.each($allPanels, function (idx, panel) {
      var panelDef = panelDefinitions()[idx];
      if (!panelRatios[panelDef.type]) {
        panelRatios[panelDef.type] = 1 / panelDefinitions().length;
      }
      totalRatios += panelRatios[panelDef.type];
      $(panel).data('minHeight', panelDef.minHeight);
    });

    // Normalize the ratios in case new panels were added or removed.
    if (totalRatios !== 1) {
      var diff = 1 / totalRatios;
      $.each(panelDefinitions(), function (idx, panel) {
        panelRatios[panel.type] = panelRatios[panel.type] * diff;
      });
    }
    var totalHeight = -1;
    var containerTop = $container.offset().top;

    // Resizes all containers according to the set ratios
    var resizeByRatio = function resizeByRatio() {
      if (totalHeight === $container.innerHeight()) {
        return;
      }
      $allPanels = $container.children('.assist-inner-panel');
      totalHeight = $container.innerHeight();
      containerTop = $container.offset().top;
      $.each($allPanels, function (idx, panel) {
        var panelDef = panelDefinitions()[idx];
        if (!panelRatios[panelDef.type] || $allPanels.length === 1) {
          panelRatios[panelDef.type] = 1 / panelDefinitions().length;
        }
        totalRatios += panelRatios[panelDef.type];
        $(panel).data('minHeight', panelDef.minHeight);
      });
      var availableForPanels = totalHeight - allExtrasHeight;
      var leftoverSpace = 0;
      $allPanels.each(function (idx, panel) {
        var $panel = $(panel);
        var desiredHeight = availableForPanels * panelRatios[panelDefinitions()[idx].type];
        var newHeight = Math.max($panel.data('minHeight'), desiredHeight);
        $panel.height(newHeight);
        leftoverSpace += newHeight - desiredHeight;
      });
      // The minheight is greater than the ratio so we shrink where possible
      if (leftoverSpace > 0) {
        $allPanels.each(function (idx, panel) {
          if (leftoverSpace === 0) {
            return false;
          }
          var $panel = $(panel);
          var currentHeight = $panel.height();
          var possibleContribution = Math.min(currentHeight - $panel.data('minHeight'), leftoverSpace);
          if (possibleContribution > 0) {
            $panel.height(currentHeight - possibleContribution);
            leftoverSpace -= possibleContribution;
          }
        });
      }
    };
    resizeByRatio();
    $(window).resize(resizeByRatio);
    window.setTimeout(resizeByRatio, 1000);
    huePubSub.subscribe('assist.forceRender', function () {
      window.setTimeout(resizeByRatio, 200);
    });
    $allExtras.show();
    $allPanels.show();
    var fitPanelHeights = function fitPanelHeights($panelsToResize, desiredTotalHeight) {
      var currentHeightOfPanels = 0;
      var noMoreSpace = true;
      $panelsToResize.each(function (idx, panel) {
        var $panel = $(panel);
        var panelHeight = $panel.outerHeight(true);
        noMoreSpace = noMoreSpace && panelHeight <= $panel.data('minHeight');
        currentHeightOfPanels += panelHeight;
      });
      var distanceToGo = desiredTotalHeight - currentHeightOfPanels;
      if (noMoreSpace && distanceToGo < 0) {
        return;
      }

      // Add all to the first panel if expanding (distanceToGo is positive
      if (distanceToGo >= 0) {
        $panelsToResize.first().height($panelsToResize.first().height() + distanceToGo + 'px');
        return;
      }

      // Remove as much as possible on each panel if shrinking (distanceToGo is negative)
      $panelsToResize.each(function (idx, panel) {
        var $panel = $(panel);
        var initialHeight = $panel.height();
        var newHeight = Math.max($panel.data('minHeight'), initialHeight + distanceToGo);
        if (initialHeight === newHeight) {
          return true;
        }
        $panel.height(newHeight);
        distanceToGo += initialHeight - newHeight;
        if (distanceToGo >= 0) {
          return false;
        }
      });
    };
    $allResizers.each(function (idx, resizer) {
      var $resizer = $(resizer);
      var extrasBeforeHeight = 0;
      $resizer.prevAll('.assist-fixed-height').each(function (idx, extra) {
        extrasBeforeHeight += $(extra).outerHeight(true);
      });
      var $panelsBefore = $resizer.prevAll('.assist-inner-panel');
      var limitBefore = extrasBeforeHeight;
      $panelsBefore.each(function (idx, panel) {
        limitBefore += $(panel).data('minHeight');
      });
      var extrasAfterHeight = allExtrasHeight - extrasBeforeHeight;
      var $panelsAfter = $resizer.nextAll('.assist-inner-panel');
      var requiredSpaceAfter = extrasAfterHeight;
      $panelsAfter.each(function (idx, panel) {
        requiredSpaceAfter += $(panel).data('minHeight');
      });
      $resizer.draggable({
        axis: 'y',
        drag: function drag(event, ui) {
          var limitAfter = totalHeight - requiredSpaceAfter;
          var position = ui.offset.top - containerTop;
          if (position > limitBefore && position < limitAfter) {
            fitPanelHeights($panelsBefore, position - extrasBeforeHeight);
            fitPanelHeights($panelsAfter, totalHeight - extrasAfterHeight - position);
          } else if (position > limitAfter) {
            fitPanelHeights($panelsBefore, limitAfter - extrasBeforeHeight);
            fitPanelHeights($panelsAfter, totalHeight - extrasAfterHeight - limitAfter);
          } else if (position < limitBefore) {
            fitPanelHeights($panelsBefore, limitBefore - extrasBeforeHeight);
            fitPanelHeights($panelsAfter, totalHeight - extrasAfterHeight - limitBefore);
          }
          ui.offset.top = 0;
          ui.position.top = 0;
        },
        stop: function stop(event, ui) {
          ui.offset.top = 0;
          ui.position.top = 0;
          var totalHeightForPanels = 0;
          $allPanels.each(function (idx, panel) {
            totalHeightForPanels += $(panel).outerHeight(true);
          });
          $allPanels.each(function (idx, panel) {
            panelRatios[panelDefinitions()[idx].type] = $(panel).outerHeight(true) / totalHeightForPanels;
          });
          setInLocalStorage('assist.innerPanelRatios', panelRatios);
        }
      });
    });
  }
};