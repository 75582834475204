// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import $ from 'jquery';
import * as ko from 'knockout';

// TODO: Depends on Role

ko.bindingHandlers.select2 = {
  init: function init(element, valueAccessor, allBindingsAccessor, viewModel) {
    var options = ko.toJS(valueAccessor()) || {};
    var $element = $(element);

    // When the options are in the binding value accessor the data attribute will be used instead of any <select>
    // tag it's attached to.
    if (ko.isObservable(valueAccessor().options) && ko.isObservable(valueAccessor().value)) {
      var optionsObservable = valueAccessor().options;
      var valueObservable = valueAccessor().value;
      options.data = $.map(optionsObservable(), function (value) {
        return {
          id: value,
          text: value
        };
      });
      options.val = valueObservable();
      var refreshSelect2Data = function refreshSelect2Data() {
        $element.select2('data', $.map(optionsObservable(), function (value) {
          return {
            id: value,
            text: value
          };
        }));
        $element.select2('val', valueObservable());
      };
      valueObservable.subscribe(function (newValue) {
        if (newValue !== $element.select2('val')) {
          refreshSelect2Data();
        }
      });
      optionsObservable.subscribe(refreshSelect2Data);
      window.setTimeout(function () {
        refreshSelect2Data();
      }, 10);
    }
    if (typeof valueAccessor().vm != 'undefined') {
      viewModel = valueAccessor().vm;
    }
    if (typeof valueAccessor().update != 'undefined') {
      if (options.type === 'user' && viewModel.selectableHadoopUsers().indexOf(options.update) === -1) {
        viewModel.availableHadoopUsers.push({
          username: options.update
        });
      }
      if (options.type === 'group') {
        if (options.update instanceof Array) {
          options.update.forEach(function (opt) {
            if (viewModel.selectableHadoopGroups().indexOf(opt) === -1) {
              viewModel.availableHadoopGroups.push({
                name: opt
              });
            }
          });
        } else if (viewModel.selectableHadoopGroups().indexOf(options.update) === -1) {
          viewModel.availableHadoopGroups.push({
            name: options.update
          });
        }
      }
      if (options.type === 'action' && viewModel.availableActions().indexOf(options.update) === -1) {
        viewModel.availableActions.push(options.update);
      }
      if (options.type === 'scope' && viewModel.availablePrivileges().indexOf(options.update) === -1) {
        viewModel.availablePrivileges.push(options.update);
      }
      if (options.type === 'parameter' && options.update !== '') {
        var _found = false;
        allBindingsAccessor().options().forEach(function (opt) {
          var _option = opt[allBindingsAccessor().optionsValue];
          if (ko.isObservable(_option)) {
            _option = _option();
          }
          if (_option === options.update) {
            _found = true;
          }
        });
        if (!_found) {
          allBindingsAccessor().options.push({
            name: ko.observable(options.update),
            value: ko.observable(options.update)
          });
        }
      }
    }
    $element.select2(options).on('change', function (e) {
      if (typeof e.val != 'undefined') {
        if (typeof valueAccessor().update != 'undefined') {
          valueAccessor().update(e.val);
        }
        if (typeof valueAccessor().value != 'undefined') {
          valueAccessor().value(e.val);
        }
      }
    }).on('select2-focus', function () {
      if (typeof options.onFocus != 'undefined') {
        options.onFocus();
      }
    }).on('select2-blur', function () {
      if (typeof options.onBlur != 'undefined') {
        options.onBlur();
      }
    }).on('select2-open', function () {
      $('.select2-input').off('keyup').data('type', options.type).on('keyup', function (e) {
        if (e.keyCode === 13) {
          var _isArray = options.update instanceof Array;
          var _newVal = $(this).val();
          var _type = $(this).data('type');
          if ($.trim(_newVal) !== '') {
            if (_type === 'user') {
              viewModel.availableHadoopUsers.push({
                username: _newVal
              });
            }
            if (_type === 'group') {
              viewModel.availableHadoopGroups.push({
                name: _newVal
              });
            }
            if (_type === 'action') {
              viewModel.availableActions.push(_newVal);
            }
            if (_type === 'scope') {
              viewModel.availablePrivileges.push(_newVal);
            }
            if (_type === 'role' && window.Role) {
              var _r = new Role(viewModel, {
                name: _newVal
              });
              viewModel.tempRoles.push(_r);
              viewModel.roles.push(_r);
            }
            if (_type === 'parameter') {
              var _found2 = false;
              allBindingsAccessor().options().forEach(function (opt) {
                if (opt[allBindingsAccessor().optionsValue]() === _newVal) {
                  _found2 = true;
                }
              });
              if (!_found2) {
                allBindingsAccessor().options.push({
                  name: ko.observable(_newVal),
                  value: ko.observable(_newVal)
                });
              }
            }
            if (_isArray) {
              var _vals = $(element).select2('val');
              _vals.push(_newVal);
              $(element).select2('val', _vals, true);
            } else {
              $(element).select2('val', _newVal, true);
            }
            $(element).select2('close');
          }
        }
      });
    });
    $element.data('select2').search.prop('readOnly', true).attr('onfocus', 'this.removeAttribute("readonly");').wrap('<form autocomplete="off">');
  },
  update: function update(element, valueAccessor, allBindingsAccessor) {
    if (typeof allBindingsAccessor().visible != 'undefined') {
      if (typeof allBindingsAccessor().visible == 'boolean' && allBindingsAccessor().visible || typeof allBindingsAccessor().visible == 'function' && allBindingsAccessor().visible()) {
        $(element).select2('container').show();
      } else {
        $(element).select2('container').hide();
      }
    }
    if (typeof valueAccessor().update != 'undefined') {
      $(element).select2('val', valueAccessor().update());
    }
    if (typeof valueAccessor().readonly != 'undefined') {
      $(element).select2('readonly', valueAccessor().readonly);
      if (typeof valueAccessor().readonlySetTo != 'undefined') {
        valueAccessor().readonlySetTo();
      }
    }
  }
};