// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import $ from 'jquery';
import * as ko from 'knockout';
import huePubSub from 'utils/huePubSub';
import scrollbarWidth from 'utils/screen/scrollbarWidth';
ko.bindingHandlers.dockable = {
  init: function init(element, valueAccessor) {
    var options = valueAccessor() || {};
    var scrollable = options.scrollable ? options.scrollable : window;
    var triggerAdjust = options.triggerAdjust || 0;
    var zIndex = options.zIndex || 1000;
    $(element).addClass('dockable');
    var initialTopPosition = -1;
    var initialSize = {
      w: $(element).width() - scrollbarWidth(),
      h: $(element).outerHeight() + (options.jumpCorrection || 0)
    };
    var ghost = $('<div>').css({
      display: 'none',
      height: initialSize.h
    }).insertBefore($(element));
    function dock() {
      if (initialTopPosition === -1) {
        initialTopPosition = $(element).position().top;
        ghost.height($(element).outerHeight() + (options.jumpCorrection || 0));
      }
      if ($(scrollable).scrollTop() + triggerAdjust > initialTopPosition) {
        $(element).attr('style', 'position: fixed!important; top: ' + options.topSnap + '; width: ' + initialSize.w + 'px!important; z-index: ' + zIndex);
        ghost.show();
      } else {
        $(element).removeAttr('style');
        ghost.hide();
      }
    }
    $(scrollable).on('scroll', dock);
    var scrollOffSubscription = huePubSub.subscribe('scrollable.scroll.off', function (scrollElement) {
      if (scrollElement === scrollable) {
        $(scrollable).on('scroll', dock);
      }
    });
    function resetInitialStyle() {
      $(element).removeAttr('style');
      initialSize = {
        w: $(element).width() - scrollbarWidth(),
        h: $(element).outerHeight() + (options.jumpCorrection || 0)
      };
      dock();
    }
    $(window).on('resize', resetInitialStyle);
    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      $(window).off('resize', resetInitialStyle);
      $(scrollable).off('scroll', dock);
      scrollOffSubscription.remove();
    });
  }
};