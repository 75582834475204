// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import $ from 'jquery';
import * as ko from 'knockout';
import I18n from "../../utils/i18n";
ko.bindingHandlers.tagEditor = {
  init: function init(element, valueAccessor) {
    var options = valueAccessor();
    var $element = $(element);
    var validRegExp = options.validRegExp ? new RegExp(options.validRegExp) : undefined;
    var showValidationError = function showValidationError() {
      var $errorWrapper = $element.siblings('.selectize-error');
      if (options.invalidMessage && $errorWrapper.length > 0) {
        $errorWrapper.find('.message').text(options.invalidMessage);
        $errorWrapper.show();
        window.setTimeout(function () {
          $errorWrapper.fadeOut(400, function () {
            $errorWrapper.hide();
          });
        }, 5000);
      }
    };
    options = $.extend({
      plugins: ['remove_button'],
      options: $.map(options.setTags(), function (value) {
        return {
          value: value,
          text: value
        };
      }),
      delimiter: ',',
      items: options.setTags(),
      closeAfterSelect: true,
      persist: true,
      preload: true,
      create: function create(input) {
        if (typeof validRegExp !== 'undefined' && !validRegExp.test(input)) {
          showValidationError();
          return false;
        }
        return {
          value: input.replace(/\s/g, '-'),
          text: input.replace(/\s/g, '-')
        };
      }
    }, options);
    var $readOnlyContainer = $('<div>').hide().addClass('selectize-control selectize-read-only multi').attr('style', $element.attr('style')).insertAfter($(element));
    if (!options.readOnly) {
      $readOnlyContainer.on('mouseover', function () {
        $readOnlyContainer.find('.selectize-actions').addClass('selectize-actions-visible');
      });
      $readOnlyContainer.on('mouseout', function () {
        $readOnlyContainer.find('.selectize-actions').removeClass('selectize-actions-visible');
      });
    }
    $element.hide();
    var currentSelectize;
    var optionsBeforeEdit = [];
    var saveOnClickOutside = function saveOnClickOutside(event) {
      if ($.contains(document, event.target) && currentSelectize && !$.contains(currentSelectize.$wrapper[0], event.target)) {
        if (currentSelectize.getValue() !== optionsBeforeEdit.join(',')) {
          options.onSave(currentSelectize.getValue());
        }
        $(document).off('click', saveOnClickOutside);
        $(document).off('keyup', hideOnEscape);
        showReadOnly();
      }
    };
    var hideOnEscape = function hideOnEscape(event) {
      if (event.which === 27) {
        showReadOnly();
      }
    };
    var sizeCheckInterval = -1;
    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      window.clearInterval(sizeCheckInterval);
    });
    var showEdit = function showEdit() {
      window.clearInterval(sizeCheckInterval);
      optionsBeforeEdit = options.setTags().concat();
      options.options = $.map(options.setTags(), function (value) {
        return {
          value: value,
          text: value
        };
      });
      currentSelectize = $element.selectize(options)[0].selectize;
      $readOnlyContainer.hide();
      $element.next().find('.selectize-input').css('padding-right', '38px');
      $element.next().find('input').focus();
      var $editActions = $('<div>').addClass('selectize-actions').appendTo($element.next());
      $('<i>').addClass('fa fa-check').click(function () {
        if (currentSelectize.getValue() !== optionsBeforeEdit.join(',')) {
          options.onSave(currentSelectize.getValue());
        }
        showReadOnly();
      }).appendTo($editActions);
      $('<i>').addClass('fa fa-close').click(function () {
        showReadOnly();
      }).appendTo($editActions);
      window.setTimeout(function () {
        $(document).on('click', saveOnClickOutside);
        $(document).on('keyup', hideOnEscape);
      }, 0);
    };
    var lastKnownOffsetWidth = -1;
    var lastKnownOffsetHeight = -1;
    var addReadOnlyTagsTillOverflow = function addReadOnlyTagsTillOverflow($readOnlyInner) {
      $readOnlyInner.empty();
      var tagElements = [];
      options.setTags().forEach(function (tag) {
        tagElements.push($('<div>').text(tag).appendTo($readOnlyInner));
      });
      if (!options.readOnly && !options.hasErrors()) {
        $('<i>').addClass('fa fa-pencil selectize-edit pointer').attr('title', I18n('Edit tags')).appendTo($readOnlyInner);
        $readOnlyInner.click(function () {
          showEdit();
        });
      }
      if (!options.overflowEllipsis) {
        return;
      }
      if ($readOnlyInner[0].offsetHeight < $readOnlyInner[0].scrollHeight || $readOnlyInner[0].offsetWidth < $readOnlyInner[0].scrollWidth && tagElements.length) {
        tagElements[tagElements.length - 1].after($('<div>').addClass('hue-tag-overflow').text('...'));
      }
      while (tagElements.length && ($readOnlyInner[0].offsetHeight < $readOnlyInner[0].scrollHeight || $readOnlyInner[0].offsetWidth < $readOnlyInner[0].scrollWidth)) {
        tagElements.pop().remove();
      }
      lastKnownOffsetWidth = $readOnlyInner[0].offsetWidth;
      lastKnownOffsetHeight = $readOnlyInner[0].offsetHeight;
    };
    var showReadOnly = function showReadOnly() {
      window.clearInterval(sizeCheckInterval);
      $(document).off('click', saveOnClickOutside);
      $(document).off('keyup', hideOnEscape);
      if (currentSelectize) {
        currentSelectize.destroy();
        $element.hide();
        $element.val(options.setTags().join(','));
      }
      $readOnlyContainer.empty();
      var $readOnlyInner = $('<div>').addClass('selectize-input items not-full has-options has-items').appendTo($readOnlyContainer);
      if (options.setTags().length > 0) {
        addReadOnlyTagsTillOverflow($readOnlyInner);
        if (options.overflowEllipsis) {
          sizeCheckInterval = window.setInterval(function () {
            if ($readOnlyInner[0].offsetWidth !== lastKnownOffsetWidth || $readOnlyInner[0].offsetHeight !== lastKnownOffsetHeight) {
              addReadOnlyTagsTillOverflow($readOnlyInner);
            }
          }, 500);
        }
      } else {
        if (options.hasErrors()) {
          $('<span>').addClass('selectize-no-tags').text(options.errorMessage).appendTo($readOnlyInner);
        } else {
          $('<span>').addClass('selectize-no-tags').text(options.emptyPlaceholder).appendTo($readOnlyInner);
        }
        if (!options.readOnly && !options.hasErrors()) {
          $('<i>').addClass('fa fa-pencil selectize-edit pointer').attr('title', I18n('Edit tags')).appendTo($readOnlyInner);
          $readOnlyInner.click(function () {
            showEdit();
          });
        }
      }
      $readOnlyContainer.attr('title', options.setTags().join(', '));
      $readOnlyContainer.show();
    };
    showReadOnly();
  }
};