// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import $ from 'jquery';
import * as ko from 'knockout';
ko.bindingHandlers.momentFromNow = {
  update: function update(element, valueAccessor) {
    var options = ko.unwrap(valueAccessor());
    var $element = $(element);
    var value = ko.unwrap(options.data);
    function render() {
      $element.text(moment(value).fromNow());
      if (options.titleFormat) {
        $element.attr('title', moment(value).format(options.titleFormat));
      }
    }
    render();
    if (options.interval) {
      window.clearInterval($element.data('momentInterval'));
      var interval = window.setInterval(render, options.interval);
      $element.data('momentInterval', interval);
      ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
        window.clearInterval(interval);
      });
    }
  }
};