import $ from 'jquery';
import * as ko from 'knockout';

/**
 * This binding can be used to emulate position sticky for any element.
 *
 * Example:
 *
 * <div databind="stickVertical: { scrollContainer: '.some-container' }">...</div>
 *
 * @type {{init: ko.bindingHandlers.stickVertical.init}}
 */
ko.bindingHandlers.stickVertical = {
  init: function init(element, valueAccessor) {
    var options = valueAccessor() || {};
    var $scrollContainer = $(options.scrollContainer || window);
    var $element = $(element);
    var $parent = $element.parent();
    var active = false;
    var throttleTimeout = -1;
    var throttledReposition = function throttledReposition() {
      window.clearTimeout(throttleTimeout);
      throttleTimeout = window.setTimeout(function () {
        var diff = $scrollContainer.offset().top - $parent.offset().top;
        if (diff > 0) {
          $element.animate({
            'margin-top': diff + 'px'
          }, 40);
          active = true;
        } else if (active) {
          $element.css({
            'margin-top': ''
          });
          active = false;
        }
      }, 50);
    };
    $scrollContainer.on('scroll.stickVertical', throttledReposition);
    $(window).on('resize.stickVertical', throttledReposition);
    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      $scrollContainer.off('scroll.stickVertical');
      $(window).off('resize.stickVertical');
    });
  }
};