// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import $ from 'jquery';
import * as ko from 'knockout';
import huePubSub from 'utils/huePubSub';

/**
 * This binding limits the rendered items based on what is visible within a scrollable container. It supports
 * multiple any amount of nested children with foreachVisible bindings
 *
 * The minHeight parameter is the initial expected rendered height of each entry, once rendered the real
 * height is used. It keeps a number of elements above and below the visible elements to make slow scrolling
 * smooth.
 *
 * The height of the container element has to be less than or equal to the inner height of the window.
 *
 * Example:
 *
 * <div class="container" style="overflow-y: scroll; height: 100px">
 *  <ul data-bind="foreachVisible: { data: items, minHeight: 20, container: '.container' }">
 *    <li>...</li>
 *  </ul>
 * </div>
 *
 * For tables the binding has to be attached to the tbody element:
 *
 * <div class="container" style="overflow-y: scroll; height: 100px">
 *  <table>
 *    <thead>...</thead>
 *    <tbody data-bind="foreachVisible: { data: items, minHeight: 20, container: '.container' }>
 *      <tr>...</tr>
 *    </tbody>
 *  </ul>
 * </div>
 *
 * Currently the binding only supports one element inside the bound element otherwise the height
 * calculations will be off. In other words this will make it go bonkers:
 *
 * <div class="container" style="overflow-y: scroll; height: 100px">
 *  <ul data-bind="foreachVisible: { data: items, minHeight: 20, container: '.container' }">
 *    <li>...</li>
 *    <li style="display: none;">...</li>
 *  </ul>
 * </div>
 *
 */
ko.bindingHandlers.foreachVisible = {
  init: function init(element, valueAccessor, allBindings, viewModel, bindingContext) {
    return ko.bindingHandlers.template.init(element, function () {
      return {
        foreach: [],
        templateEngine: ko.nativeTemplateEngine.instance
      };
    }, allBindings, viewModel, bindingContext);
  },
  update: function update(element, valueAccessor, allBindings, viewModel, bindingContext) {
    var options = valueAccessor();
    var $element = $(element);
    var isTable = false;
    if ($element.parent().is('table')) {
      $element = $element.parent();
      isTable = true;
    }
    var $container = $element.closest(options.container);
    var id = Math.random();

    // This is possibly a parent element that has the foreachVisible binding
    var $parentFVElement = bindingContext.$parentForeachVisible || null;
    var parentId = bindingContext.$parentForeachVisibleId || -1;
    // This is the element from the parent foreachVisible rendered element that contains
    // this one or container for root
    var $parentFVOwnerElement = $container;
    $element.data('parentForeachVisible', $parentFVElement);
    var depth = bindingContext.$depth || 0;

    // Locate the owning element if within another foreach visible binding
    if ($parentFVElement) {
      var myOffset = $element.offset().top;
      $parentFVElement.children().each(function (idx, child) {
        var $child = $(child);
        if (myOffset > $child.offset().top) {
          $parentFVOwnerElement = $child;
        } else {
          return false;
        }
      });
    }
    if ($parentFVOwnerElement.data('disposalFunction')) {
      $parentFVOwnerElement.data('disposalFunction')();
      $parentFVOwnerElement.data('lastKnownHeights', null);
    }
    var entryMinHeight = options.minHeight;
    var allEntries = ko.utils.unwrapObservable(options.data);
    var visibleEntryCount = 0;
    var incrementLimit = 0; // The diff required to re-render, set to visibleCount below
    var elementIncrement = 0; // Elements to add on either side of the visible elements, set to 3x visibleCount
    var endIndex = 0;
    var updateVisibleEntryCount = function updateVisibleEntryCount() {
      // TODO: Drop the window innerHeight limitation.
      // Sometimes after resizeWrapper() is called the reported innerHeight of the $container is the same as
      // the wrapper causing the binding to render all the items. This limits the visibleEntryCount to the
      // window height.
      var newEntryCount = Math.ceil(Math.min($(window).innerHeight(), $container.innerHeight()) / entryMinHeight);
      if (newEntryCount !== visibleEntryCount) {
        var diff = newEntryCount - visibleEntryCount;
        elementIncrement = options.elementIncrement || 25;
        incrementLimit = options.incrementLimit || 5;
        visibleEntryCount = newEntryCount;
        endIndex += diff;
        huePubSub.publish('foreach.visible.update', id);
      }
    };

    // TODO: Move intervals to webworker
    var updateCountInterval = setInterval(updateVisibleEntryCount, 300);
    updateVisibleEntryCount();

    // In case this element was rendered before use the last known indices
    var startIndex = Math.max($parentFVOwnerElement.data('startIndex') || 0, 0);
    endIndex = Math.min($parentFVOwnerElement.data('endIndex') || visibleEntryCount + elementIncrement, allEntries.length - 1);
    var huePubSubs = [];
    var _scrollToIndex = function scrollToIndex(idx, offset, instant, callback) {
      var lastKnownHeights = $parentFVOwnerElement.data('lastKnownHeights');
      if (!lastKnownHeights || lastKnownHeights.length <= idx) {
        return;
      }
      var top = 0;
      for (var i = 0; i < idx; i++) {
        top += lastKnownHeights[i];
      }
      var bottom = top + lastKnownHeights[idx];
      window.setTimeout(function () {
        var newScrollTop = top + offset;
        if (instant) {
          if (newScrollTop >= $container.height() + $container.scrollTop()) {
            $container.scrollTop(bottom - $container.height());
          } else if (newScrollTop <= $container.scrollTop()) {
            $container.scrollTop(newScrollTop);
          }
        } else {
          $container.stop().animate({
            scrollTop: newScrollTop
          }, '500', 'swing', function () {
            if (callback) {
              callback();
            }
          });
        }
      }, 0);
    };
    if (!options.skipScrollEvent) {
      huePubSubs.push(huePubSub.subscribe('assist.db.scrollTo', function (targetEntry) {
        var foundIndex = -1;
        $.each(allEntries, function (idx, entry) {
          if (targetEntry === entry) {
            foundIndex = idx;
            return false;
          }
        });
        if (foundIndex !== -1) {
          var offset = depth > 0 ? $parentFVOwnerElement.position().top : 0;
          _scrollToIndex(foundIndex, offset, false, function () {
            huePubSub.publish('assist.db.scrollToComplete', targetEntry);
          });
        }
      }));
    }
    if (ko.isObservable(viewModel.foreachVisible)) {
      viewModel.foreachVisible({
        scrollToIndex: function scrollToIndex(index) {
          var offset = depth > 0 ? $parentFVOwnerElement.position().top : 0;
          _scrollToIndex(index, offset, true);
        }
      });
    }
    var childBindingContext = bindingContext.createChildContext(bindingContext.$rawData, null, function (context) {
      ko.utils.extend(context, {
        $parentForeachVisible: $element,
        $parentForeachVisibleId: id,
        $depth: depth + 1,
        $indexOffset: function $indexOffset() {
          return startIndex;
        }
      });
    });
    var $wrapper = $element.parent();
    if (!$wrapper.hasClass('foreach-wrapper')) {
      $wrapper = $('<div>').css({
        position: 'relative',
        width: '100%'
      }).addClass('foreach-wrapper').insertBefore($element);
      if (options.usePreloadBackground) {
        $wrapper.addClass('assist-preloader-wrapper');
        $element.addClass('assist-preloader');
      }
      $element.css({
        position: 'absolute',
        top: 0,
        width: '100%'
      }).appendTo($wrapper);
    }

    // This is kept up to date with the currently rendered elements, it's used to keep track of any
    // height changes of the elements.
    var renderedElements = [];
    if (!$parentFVOwnerElement.data('lastKnownHeights') || $parentFVOwnerElement.data('lastKnownHeights').length !== allEntries.length) {
      var lastKnownHeights = [];
      $.each(allEntries, function () {
        lastKnownHeights.push(entryMinHeight);
      });
      $parentFVOwnerElement.data('lastKnownHeights', lastKnownHeights);
    }
    var resizeWrapper = function resizeWrapper() {
      var totalHeight = 0;
      var lastKnownHeights = $parentFVOwnerElement.data('lastKnownHeights');
      if (!lastKnownHeights) {
        return;
      }
      $.each(lastKnownHeights, function (idx, height) {
        totalHeight += height;
      });
      $wrapper.height(totalHeight + 'px');
    };
    resizeWrapper();
    var updateLastKnownHeights = function updateLastKnownHeights() {
      if ($container.data('busyRendering')) {
        return;
      }
      var lastKnownHeights = $parentFVOwnerElement.data('lastKnownHeights');
      // Happens when closing first level and the third level is open, disposal tells the parents
      // to update their heights...
      if (!lastKnownHeights) {
        return;
      }
      var diff = false;
      var updateEntryCount = false;
      $.each(renderedElements, function (idx, renderedElement) {
        // TODO: Figure out why it goes over index at the end scroll position
        if (startIndex + idx < lastKnownHeights.length) {
          var renderedHeight = $(renderedElement).outerHeight(true);
          if (renderedHeight > 5 && lastKnownHeights[startIndex + idx] !== renderedHeight) {
            if (renderedHeight < entryMinHeight) {
              entryMinHeight = renderedHeight;
              updateEntryCount = true;
            }
            lastKnownHeights[startIndex + idx] = renderedHeight;
            diff = true;
          }
        }
      });
      if (updateEntryCount) {
        updateVisibleEntryCount();
      }
      // Only resize if a difference in height was noticed.
      if (diff) {
        $parentFVOwnerElement.data('lastKnownHeights', lastKnownHeights);
        resizeWrapper();
      }
    };
    var updateHeightsInterval = window.setInterval(updateLastKnownHeights, 600);
    huePubSubs.push(huePubSub.subscribe('foreach.visible.update.heights', function (targetId) {
      if (targetId === id) {
        clearInterval(updateHeightsInterval);
        updateLastKnownHeights();
        huePubSub.publish('foreach.visible.update.heights', parentId);
        updateHeightsInterval = window.setInterval(updateLastKnownHeights, 600);
      }
    }));
    updateLastKnownHeights();
    var positionList = function positionList() {
      var lastKnownHeights = $parentFVOwnerElement.data('lastKnownHeights');
      if (!lastKnownHeights) {
        return;
      }
      var top = 0;
      for (var i = 0; i < startIndex; i++) {
        top += lastKnownHeights[i];
      }
      $element.css('top', top + 'px');
    };
    var _afterRender = function afterRender() {
      renderedElements = isTable ? $element.children('tbody').children() : $element.children();
      $container.data('busyRendering', false);
      if (typeof options.fetchMore !== 'undefined' && endIndex === allEntries.length - 1) {
        options.fetchMore();
      }
      huePubSub.publish('foreach.visible.update.heights', id);
    };
    var render = function render() {
      if (endIndex === 0 && allEntries.length > 1 || endIndex < 0) {
        ko.bindingHandlers.template.update(element, function () {
          return {
            foreach: [],
            templateEngine: ko.nativeTemplateEngine.instance,
            afterRender: function afterRender() {
              // This is called once for each added element (not when elements are removed)
              clearTimeout(throttle);
              throttle = setTimeout(_afterRender, 0);
            }
          };
        }, allBindings, viewModel, childBindingContext);
        return;
      }
      $container.data('busyRendering', true);
      // Save the start and end index for when the list is removed and is shown again.
      $parentFVOwnerElement.data('startIndex', startIndex);
      $parentFVOwnerElement.data('endIndex', endIndex);
      positionList();

      // This is to ensure that our afterRender is called (the afterRender of KO below isn't called
      // when only elements are removed)
      var throttle = setTimeout(_afterRender, 0);
      ko.bindingHandlers.template.update(element, function () {
        return {
          foreach: allEntries.slice(startIndex, endIndex + 1),
          templateEngine: ko.nativeTemplateEngine.instance,
          afterRender: function afterRender() {
            // This is called once for each added element (not when elements are removed)
            clearTimeout(throttle);
            throttle = setTimeout(_afterRender, 0);
          }
        };
      }, allBindings, viewModel, childBindingContext);
    };
    var setStartAndEndFromScrollTop = function setStartAndEndFromScrollTop() {
      var lastKnownHeights = $parentFVOwnerElement.data('lastKnownHeights');
      if (!lastKnownHeights) {
        return;
      }
      var parentSpace = 0;
      var $lastParent = $parentFVElement;
      var $lastRef = $element;
      var _loop = function _loop() {
        // Include the header, parent() is .foreach-wrapper, parent().parent() is the container (ul or div)
        var lastRefOffset = $lastRef.parent().parent().offset().top;
        var lastAddedSpace = 0;
        $lastParent.children().each(function (idx, child) {
          var $child = $(child);
          if (lastRefOffset > $child.offset().top) {
            lastAddedSpace = $child.outerHeight(true);
            parentSpace += lastAddedSpace;
          } else {
            // Remove the height of the child which is the parent of this
            parentSpace -= lastAddedSpace;
            return false;
          }
        });
        parentSpace += $lastParent.position().top;
        $lastRef = $lastParent;
        $lastParent = $lastParent.data('parentForeachVisible');
      };
      while ($lastParent) {
        _loop();
      }
      var position = Math.min($container.scrollTop() - parentSpace, $wrapper.height());
      for (var i = 0; i < lastKnownHeights.length; i++) {
        position -= lastKnownHeights[i];
        if (position <= 0) {
          startIndex = Math.max(i - elementIncrement, 0);
          endIndex = Math.min(allEntries.length - 1, i + elementIncrement + visibleEntryCount);
          break;
        }
      }
    };
    var renderThrottle = -1;
    var preloadGhostThrottle = -1;
    var lastScrollTop = -1;
    var onScroll = function onScroll() {
      if (startIndex > incrementLimit && Math.abs(lastScrollTop - $container.scrollTop()) < incrementLimit * options.minHeight) {
        return;
      }
      lastScrollTop = $container.scrollTop();
      setStartAndEndFromScrollTop();

      // adds a preload ghost image just on scroll and removes it 200ms after the scroll stops
      if (options.usePreloadBackground) {
        $wrapper.addClass('assist-preloader-ghost');
        clearTimeout(preloadGhostThrottle);
        preloadGhostThrottle = setTimeout(function () {
          $wrapper.removeClass('assist-preloader-ghost');
        }, 200);
      }
      clearTimeout(renderThrottle);
      var startDiff = Math.abs($parentFVOwnerElement.data('startIndex') - startIndex);
      var endDiff = Math.abs($parentFVOwnerElement.data('endIndex') - endIndex);
      if (startDiff > incrementLimit || endDiff > incrementLimit || startDiff !== 0 && startIndex === 0 || endDiff !== 0 && endIndex === allEntries.length - 1) {
        renderThrottle = setTimeout(render, 0);
      }
    };
    huePubSubs.push(huePubSub.subscribe('foreach.visible.update', function (callerId) {
      if (callerId === id && endIndex > 0) {
        setStartAndEndFromScrollTop();
        clearTimeout(renderThrottle);
        renderThrottle = setTimeout(render, 0);
      }
    }));
    $container.bind('scroll', onScroll);
    $parentFVOwnerElement.data('disposalFunction', function () {
      setTimeout(function () {
        huePubSub.publish('foreach.visible.update.heights', parentId);
      }, 0);
      huePubSubs.forEach(function (pubSub) {
        pubSub.remove();
      });
      $container.unbind('scroll', onScroll);
      clearInterval(updateCountInterval);
      clearInterval(updateHeightsInterval);
      $parentFVOwnerElement.data('disposalFunction', null);
    });
    if (typeof options.pubSubDispose !== 'undefined') {
      huePubSubs.push(huePubSub.subscribe(options.pubSubDispose, $parentFVOwnerElement.data('disposalFunction')));
    }
    ko.utils.domNodeDisposal.addDisposeCallback($wrapper[0], $parentFVOwnerElement.data('disposalFunction'));
    setStartAndEndFromScrollTop();
    render();
  }
};
ko.expressionRewriting.bindingRewriteValidators['foreachVisible'] = false;
ko.virtualElements.allowedBindings['foreachVisible'] = true;