// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import $ from 'jquery';
import * as ko from 'knockout';
ko.bindingHandlers.parseArguments = {
  init: function init(element, valueAccessor) {
    var $el = $(element);
    function splitStrings(str) {
      var bits = [];
      var isInQuotes = false;
      var tempStr = '';
      var charQuote = null;
      str.replace(/<\/?arg>|<\/?command>/gi, ' ').replace(/\r?\n|\r/g, '').replace(/\s\s+/g, ' ').split('').forEach(function (_char) {
        if ((_char === '"' || _char === "'") && (!charQuote || charQuote == _char)) {
          charQuote = isInQuotes ? null : _char;
          isInQuotes = !isInQuotes;
        } else if ((_char === ' ' || _char === '\n') && !isInQuotes && tempStr !== '') {
          bits.push(tempStr);
          tempStr = '';
        } else {
          tempStr += _char;
        }
      });
      if (tempStr !== '') {
        bits.push(tempStr);
      }
      return bits;
    }
    $el.bind('paste', function (e) {
      var pasted = e.originalEvent.clipboardData.getData('text');
      var args = splitStrings(pasted);
      if (args.length > 1) {
        var newList = [];
        args.forEach(function (arg) {
          var obj = {};
          obj[valueAccessor().objectKey] = $.trim(arg);
          newList.push(obj);
        });
        valueAccessor().list(ko.mapping.fromJS(newList)());
        valueAccessor().callback();
      }
    });
  }
};