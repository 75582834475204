// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import $ from 'jquery';
import * as ko from 'knockout';
ko.bindingHandlers.autogrowInput = {
  init: function init(element, valueAccessor) {
    var o = $.extend({
      minWidth: 0,
      maxWidth: 1000,
      comfortZone: 70
    }, valueAccessor());
    var minWidth = o.minWidth || $(element).width();
    var input = $(element);
    var testSubject = $('<tester></tester>').css({
      position: 'absolute',
      top: -9999,
      left: -9999,
      width: 'auto',
      fontSize: input.css('fontSize'),
      fontFamily: input.css('fontFamily'),
      fontWeight: input.css('fontWeight'),
      letterSpacing: input.css('letterSpacing'),
      whiteSpace: 'nowrap'
    });
    var val = '';
    var check = function check() {
      if (val === (val = input.val())) {
        return;
      }
      var escaped = val.replace(/&/g, '&amp;').replace(/\s/g, ' ').replace(/</g, '&lt;').replace(/>/g, '&gt;');
      testSubject.html(escaped);
      var testerWidth = testSubject.width(),
        newWidth = testerWidth + o.comfortZone >= minWidth ? testerWidth + o.comfortZone : minWidth,
        currentWidth = input.width(),
        isValidWidthChange = newWidth < currentWidth && newWidth >= minWidth || newWidth > minWidth && newWidth < o.maxWidth;
      if (isValidWidthChange) {
        input.width(newWidth);
      }
    };
    testSubject.insertAfter(element);
    ko.utils.registerEventHandler(element, 'keyup keydown blur update', check);
  }
};