// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import $ from 'jquery';
import * as ko from 'knockout';
import huePubSub from 'utils/huePubSub';
import { hueLocalStorage } from 'utils/storageUtils';
ko.bindingHandlers.aceResizer = {
  init: function init(element, valueAccessor) {
    var options = ko.unwrap(valueAccessor());
    var ace = options.snippet.ace.bind(options.snippet);
    var $target = $(options.target);
    var $resizer = $(element);
    var $contentPanel = $('.content-panel');
    var $execStatus = $resizer.prev('.snippet-execution-status');
    var $variables = $resizer.siblings('.snippet-row').find('.variables');
    var lastEditorSize = hueLocalStorage('hue.editor.editor.size') || 131;
    var editorHeight = Math.floor(lastEditorSize / 16);
    $target.height(lastEditorSize);
    var autoExpand = typeof options.snippet.aceAutoExpand !== 'undefined' ? options.snippet.aceAutoExpand : true;
    var draggedOnce = false;
    function throttleChange() {
      if (autoExpand && !draggedOnce) {
        var maxAutoLines = Math.floor(($(window).height() - 80) / 2 / 16);
        var resized = false;
        if (ace().session.getLength() > editorHeight) {
          if (ace().session.getLength() < maxAutoLines) {
            $target.height(ace().session.getLength() * 16);
          } else {
            $target.height(maxAutoLines * 16); // height of maxAutoLines
          }
          resized = true;
        } else {
          $target.height(Math.max(ace().session.getLength() * 16, hueLocalStorage('hue.editor.editor.size') || 131));
          resized = true;
        }
        if (ace().session.getLength() === editorHeight) {
          resized = false;
        }
        if (resized && $target.height() !== lastEditorSize) {
          ace().resize();
          editorHeight = Math.min(maxAutoLines, ace().session.getLength());
          lastEditorSize = $target.height();
          huePubSub.publish('redraw.fixed.headers');
        }
      }
    }
    var changeTimeout = -1;
    ace().on('change', function () {
      window.clearTimeout(changeTimeout);
      changeTimeout = window.setTimeout(throttleChange, 10);
    });
    var setAutoExpandSubscription = huePubSub.subscribe('ace.set.autoexpand', function (options) {
      if (ace().container.id === options.snippet.id()) {
        autoExpand = options.autoExpand;
      }
    });
    $resizer.draggable({
      axis: 'y',
      start: options.onStart ? options.onStart : function () {},
      drag: function drag(event, ui) {
        draggedOnce = true;
        var currentHeight = ui.offset.top + $contentPanel.scrollTop() - (125 + $execStatus.outerHeight(true) + $variables.outerHeight(true));
        $target.css('height', currentHeight + 'px');
        ace().resize();
        ui.offset.top = 0;
        ui.position.top = 0;
      },
      stop: function stop(event, ui) {
        ui.offset.top = 0;
        ui.position.top = 0;
        hueLocalStorage('hue.editor.editor.size', $target.height());
        huePubSub.publish('redraw.fixed.headers');
        $(document).trigger('editorSizeChanged');
      }
    });
    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      setAutoExpandSubscription.remove();
    });
  }
};