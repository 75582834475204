// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import $ from 'jquery';
import "./jquery.migration";
import 'jquery-contextmenu'; // TODO: Remove, it's only used for "old" query builder
import 'jquery.cookie';
import 'jquery-form';
import "./plugins/jquery.selectize";
import 'selectize-plugin-clear';
import 'jquery-ui/ui/widgets/autocomplete';
import 'jquery-ui/ui/widgets/mouse';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/droppable';
import 'jquery-ui/ui/widgets/resizable';
import 'jquery-ui/ui/widgets/sortable';
import "../ext/jquery.chosen.1.0.0.min";
import "../ext/jquery.dataTables.1.8.2.min";
import "../ext/jquery.select2.3.5.1.min";
import "./plugins/jquery.datatables.sorting";
import "./plugins/jquery.delayedinput";
import "./plugins/jquery.filechooser";
import "./plugins/jquery.hdfs.autocomplete";
import "./plugins/jquery.hiveautocomplete";
import "./plugins/jquery.horizontalscrollbar";
import "./plugins/jquery.huedatatable";
import "./plugins/jquery.rowselector";
import "./plugins/jquery.scrollleft";
import "./plugins/jquery.scrollup";
import "./plugins/jquery.selector";
import "./plugins/jquery.tableextender";
import "./plugins/jquery.tableextender2";
import "./plugins/jquery.tablescroller";
import "./plugins/jquery.titleupdater";
window.$ = $;
window.jQuery = $;
export default $;