// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import $ from 'jquery';
import * as ko from 'knockout';
import waitForRendered from 'utils/timing/waitForRendered';
ko.bindingHandlers.logScroller = {
  init: function init(element, valueAccessor, allBindings) {
    var $element = $(element);
    $element.on('scroll', function () {
      $element.data('lastScrollTop', $element.scrollTop());
    });
    $element.on('wheel', function () {
      $element.data('hasUserScrolled', true);
    });
    function autoLogScroll() {
      var elementHeight = $element.innerHeight();
      var lastHeight = $element.data('lastHeight') || elementHeight;
      var lastScrollTop = $element.data('lastScrollTop') || 0;
      var hasUserScrolled = $element.data('hasUserScrolled') || false;
      var lastScrollHeight = $element.data('lastScrollHeight') || elementHeight;
      var stickToBottom = !hasUserScrolled || elementHeight !== lastHeight || lastScrollTop + elementHeight === lastScrollHeight;
      if (stickToBottom) {
        $element.scrollTop(element.scrollHeight - $element.height());
        $element.data('lastScrollTop', $element.scrollTop());
      }
      $element.data('lastScrollHeight', element.scrollHeight);
      $element.data('lastHeight', elementHeight);
    }
    var logValue = valueAccessor();
    logValue.subscribe(function () {
      window.setTimeout(autoLogScroll, 200);
    });
    if (typeof allBindings().logScrollerVisibilityEvent !== 'undefined') {
      allBindings().logScrollerVisibilityEvent.subscribe(function () {
        window.setTimeout(autoLogScroll, 0);
      });
    } else {
      waitForRendered(element, function (el) {
        return el.is(':visible');
      }, autoLogScroll, 300);
      ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
        window.clearTimeout($element.data('waitForRenderTimeout'));
      });
    }
  }
};