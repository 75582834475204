// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import $ from 'jquery';
import * as ko from 'knockout';
import huePubSub from 'utils/huePubSub';
ko.bindingHandlers.templatePopover = {
  init: function init(element, valueAccessor, allBindingsAccessor, viewModel) {
    var options = ko.unwrap(valueAccessor());
    var clickTrigger = options.trigger === 'click';
    var $container = $('#popover-container');
    if (!$container.length) {
      $container = $('<div>').attr('id', 'popover-container').appendTo('body');
      $('<div>').addClass('temp-content').hide().appendTo($container);
      $('<div>').addClass('temp-title').hide().appendTo($container);
    }
    var $content = $container.find('.temp-content');
    var $title = $container.find('.temp-title');
    $.extend(options, {
      html: true,
      trigger: 'manual',
      container: '#popover-container'
    });
    var $element = $(element);
    var visible = options.visible || ko.observable(false);
    var trackElementInterval = -1;
    var hidePopover = function hidePopover() {
      if (visible()) {
        window.clearInterval(trackElementInterval);
        $element.popover('hide');
        visible(false);
        $(document).off('click', hideOnClickOutside);
      }
    };
    var closeSub = huePubSub.subscribe('close.popover', hidePopover);
    var hideOnClickOutside = function hideOnClickOutside(event) {
      if (visible() && $element.data('popover') && !$.contains($element.data('popover').$tip[0], event.target)) {
        hidePopover();
      }
    };
    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      if (visible && $element.data('popover')) {
        hidePopover();
      }
      closeSub.remove();
    });
    var _afterRender = function afterRender() {
      options.content = $content.html();
      options.title = $title.html();
      var triggerTitle;
      if ($element.attr('title')) {
        triggerTitle = $element.attr('title');
        $element.attr('title', null);
      }
      $element.popover(options);
      $element.popover('show');
      if (triggerTitle) {
        $element.attr('title', triggerTitle);
      }
      var $tip = $element.data('popover').$tip;
      ko.cleanNode($tip.get(0));
      ko.applyBindings(viewModel, $tip.get(0));
      $tip.find('.close-popover, .close-template-popover').click(function (event) {
        hidePopover();
        event.stopPropagation();
      });
      if (options.minWidth) {
        var heightBefore = $tip.outerHeight(true);
        var widthBefore = $tip.outerWidth(true);
        $tip.css('min-width', options.minWidth);
        // The min-width might affect the height/width in which case we reposition the popover depending on placement
        var heightDiff = (heightBefore - $tip.outerHeight(true)) / 2;
        var widthDiff = (widthBefore - $tip.outerWidth(true)) / 2;
        if ((!options.placement || options.placement === 'left' || options.placement === 'right') && heightDiff !== 0) {
          $tip.css('top', $tip.position().top + heightDiff + 'px');
        } else if (options.placement && (options.placement === 'bottom' || options.placement === 'top') && widthDiff !== 0) {
          $tip.css('left', $tip.position().left + widthDiff + 'px');
        }
      }
      var lastWidth = $element.outerWidth(true);
      var lastOffset = $element.offset();
      var lastHeight = $element.outerHeight(true);
      trackElementInterval = window.setInterval(function () {
        var elementWidth = $element.outerWidth(true);
        var elementHeight = $element.outerHeight(true);
        var elementOffset = $element.offset();
        if (lastHeight !== elementHeight || lastWidth !== $element.outerWidth(true) || lastOffset.top !== elementOffset.top || lastOffset.left !== elementOffset.left) {
          $tip.css({
            left: elementOffset.left + elementWidth / 2 - $tip.outerWidth(true) / 2,
            top: elementOffset.top + elementHeight + 10
          });
          lastWidth = elementWidth;
          lastOffset = elementOffset;
          lastHeight = elementHeight;
        }
      }, 50);
      $content.empty();
      $title.empty();
      $(document).on('click', hideOnClickOutside);
      visible(true);
    };
    var showPopover = function showPopover(preventClose) {
      if (!preventClose) {
        huePubSub.publish('close.popover');
      }
      ko.renderTemplate(options.contentTemplate, viewModel, {
        afterRender: function afterRender() {
          if (options.titleTemplate) {
            ko.renderTemplate(options.titleTemplate, viewModel, {
              afterRender: function afterRender() {
                _afterRender();
              }
            }, $title.get(0), 'replaceChildren');
          } else {
            _afterRender();
          }
        }
      }, $content.get(0), 'replaceChildren');
    };
    if (visible()) {
      window.setTimeout(function () {
        showPopover(true);
      }, 0);
    }
    if (clickTrigger) {
      $element.click(function (e) {
        if (visible()) {
          hidePopover();
        } else {
          showPopover();
        }
        e.stopPropagation();
      });
    } else {
      $element.mouseenter(showPopover);
      $element.mouseleave(hidePopover);
    }
  }
};