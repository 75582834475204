// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import $ from 'jquery';
import * as ko from 'knockout';
import ace from 'ext/aceHelper';
import { format } from '@gethue/sql-formatter';
import AceLocationHandler, { REFRESH_STATEMENT_LOCATIONS_EVENT } from "./aceLocationHandler";
import AceGutterHandler from "./aceGutterHandler";
import { registerBinding } from "../bindingUtils";
import huePubSub from 'utils/huePubSub';
import { getFromLocalStorage, setInLocalStorage } from 'utils/storageUtils';
export var NAME = 'aceEditor';
export var INSERT_AT_CURSOR_EVENT = 'editor.insert.at.cursor';
registerBinding(NAME, {
  init: function init(element, valueAccessor) {
    var $el = $(element);
    var options = ko.unwrap(valueAccessor());
    var snippet = options.snippet;
    var aceOptions = options.aceOptions || {};
    var disposeFunctions = [];
    var dispose = function dispose() {
      disposeFunctions.forEach(function (dispose) {
        dispose();
      });
    };
    ko.utils.domNodeDisposal.addDisposeCallback(element, dispose);
    $el.text(snippet.statement_raw());
    var editor = ace.edit(snippet.id());
    var AceRange = ace.require('ace/range').Range;
    var resizeAce = function resizeAce() {
      window.setTimeout(function () {
        try {
          editor.resize(true);
        } catch (e) {
          // Can happen when the editor hasn't been initialized
        }
      }, 0);
    };
    var assistToggleSub = huePubSub.subscribe('assist.set.manual.visibility', resizeAce);
    var resizePubSub = huePubSub.subscribe('split.panel.resized', resizeAce);
    disposeFunctions.push(function () {
      assistToggleSub.remove();
      resizePubSub.remove();
    });
    var aceLocationHandler = new AceLocationHandler({
      editor: editor,
      editorId: $el.attr('id'),
      snippet: snippet,
      i18n: {
        expandStar: options.expandStar,
        contextTooltip: options.contextTooltip
      }
    });
    var aceGutterHandler = new AceGutterHandler({
      editor: editor,
      editorId: $el.attr('id'),
      executor: snippet.executor
    });
    disposeFunctions.push(function () {
      aceLocationHandler.dispose();
      aceGutterHandler.dispose();
    });
    editor.session.setMode(snippet.getAceMode());
    editor.setOptions({
      fontSize: getFromLocalStorage('hue.ace.fontSize', navigator.platform && navigator.platform.toLowerCase().indexOf('linux') > -1 ? '14px' : '12px')
    });
    function processErrorsAndWarnings(type, list) {
      editor.clearErrorsAndWarnings(type);
      var offset = 0;
      if (snippet.isSqlDialect() && editor.getSelectedText()) {
        var selectionRange = editor.getSelectionRange();
        offset = Math.min(selectionRange.start.row, selectionRange.end.row);
      }
      if (list.length > 0) {
        list.forEach(function (item, cnt) {
          if (item.line !== null) {
            if (type === 'error') {
              editor.addError(item.message, item.line + offset);
            } else {
              editor.addWarning(item.message, item.line + offset);
            }
            if (cnt === 0) {
              editor.scrollToLine(item.line + offset, true, true, function () {});
              if (item.col !== null) {
                editor.renderer.scrollCursorIntoView({
                  row: item.line + offset,
                  column: item.col + 10
                }, 0.5);
              }
            }
          }
        });
      }
    }
    var errorsSub = snippet.errors.subscribe(function (newErrors) {
      processErrorsAndWarnings('error', newErrors);
    });
    var aceWarningsSub = snippet.aceWarnings.subscribe(function (newWarnings) {
      processErrorsAndWarnings('warning', newWarnings);
    });
    var aceErrorsSub = snippet.aceErrors.subscribe(function (newErrors) {
      processErrorsAndWarnings('error', newErrors);
    });
    disposeFunctions.push(function () {
      errorsSub.dispose();
      aceWarningsSub.dispose();
      aceErrorsSub.dispose();
    });
    var darkThemeEnabled = getFromLocalStorage('ace.dark.theme.enabled', false);
    editor.setTheme(darkThemeEnabled ? 'ace/theme/hue_dark' : 'ace/theme/hue');
    var editorOptions = {
      enableSnippets: true,
      showGutter: false,
      showLineNumbers: false,
      showPrintMargin: false,
      scrollPastEnd: 0.1,
      minLines: 1,
      maxLines: 25
    };
    editor.enabledMenuOptions = {
      setShowInvisibles: true,
      setTabSize: true,
      setShowGutter: true
    };
    editor.customMenuOptions = {
      setEnableDarkTheme: function setEnableDarkTheme(enabled) {
        darkThemeEnabled = enabled;
        setInLocalStorage('ace.dark.theme.enabled', darkThemeEnabled);
        editor.setTheme(darkThemeEnabled ? 'ace/theme/hue_dark' : 'ace/theme/hue');
      },
      getEnableDarkTheme: function getEnableDarkTheme() {
        return darkThemeEnabled;
      },
      setEnableAutocompleter: function setEnableAutocompleter(enabled) {
        editor.setOption('enableBasicAutocompletion', enabled);
        setInLocalStorage('hue.ace.enableBasicAutocompletion', enabled);
        var $enableLiveAutocompletionChecked = $('#setEnableLiveAutocompletion:checked');
        var $setEnableLiveAutocompletion = $('#setEnableLiveAutocompletion');
        if (enabled && $enableLiveAutocompletionChecked.length === 0) {
          $setEnableLiveAutocompletion.trigger('click');
        } else if (!enabled && $enableLiveAutocompletionChecked.length !== 0) {
          $setEnableLiveAutocompletion.trigger('click');
        }
      },
      getEnableAutocompleter: function getEnableAutocompleter() {
        return editor.getOption('enableBasicAutocompletion');
      },
      setEnableLiveAutocompletion: function setEnableLiveAutocompletion(enabled) {
        editor.setOption('enableLiveAutocompletion', enabled);
        setInLocalStorage('hue.ace.enableLiveAutocompletion', enabled);
        if (enabled && $('#setEnableAutocompleter:checked').length === 0) {
          $('#setEnableAutocompleter').trigger('click');
        }
      },
      getEnableLiveAutocompletion: function getEnableLiveAutocompletion() {
        return editor.getOption('enableLiveAutocompletion');
      },
      setFontSize: function setFontSize(size) {
        if (size.toLowerCase().indexOf('px') === -1 && size.toLowerCase().indexOf('em') === -1) {
          size += 'px';
        }
        editor.setOption('fontSize', size);
        setInLocalStorage('hue.ace.fontSize', size);
      },
      getFontSize: function getFontSize() {
        var size = editor.getOption('fontSize');
        if (size.toLowerCase().indexOf('px') === -1 && size.toLowerCase().indexOf('em') === -1) {
          size += 'px';
        }
        return size;
      }
    };
    if (window.ENABLE_SQL_SYNTAX_CHECK && window.Worker) {
      var errorHighlightingEnabled = getFromLocalStorage('hue.ace.errorHighlightingEnabled', true);
      if (errorHighlightingEnabled) {
        aceLocationHandler.attachSqlSyntaxWorker();
      }
      editor.customMenuOptions.setErrorHighlighting = function (enabled) {
        errorHighlightingEnabled = enabled;
        setInLocalStorage('hue.ace.errorHighlightingEnabled', enabled);
        if (enabled) {
          aceLocationHandler.attachSqlSyntaxWorker();
        } else {
          aceLocationHandler.detachSqlSyntaxWorker();
        }
      };
      editor.customMenuOptions.getErrorHighlighting = function () {
        return errorHighlightingEnabled;
      };
      editor.customMenuOptions.setClearIgnoredSyntaxChecks = function () {
        setInLocalStorage('hue.syntax.checker.suppressedRules', {});
        $('#setClearIgnoredSyntaxChecks').hide().before('<div style="margin-top:5px;float:right;">done</div>');
      };
      editor.customMenuOptions.getClearIgnoredSyntaxChecks = function () {
        return false;
      };
    }
    $.extend(editorOptions, aceOptions);
    editorOptions['enableBasicAutocompletion'] = getFromLocalStorage('hue.ace.enableBasicAutocompletion', true);
    if (editorOptions['enableBasicAutocompletion']) {
      editorOptions['enableLiveAutocompletion'] = getFromLocalStorage('hue.ace.enableLiveAutocompletion', true);
    }
    editorOptions['tabSize'] = 2;
    editorOptions['useSoftTabs'] = true;
    editor.setOptions(editorOptions);
    var AceAutocomplete = ace.require('ace/autocomplete').Autocomplete;
    if (!editor.completer) {
      editor.completer = new AceAutocomplete();
    }
    editor.completer.exactMatch = !snippet.isSqlDialect();
    var initAutocompleters = function initAutocompleters() {
      if (editor.completers) {
        editor.completers.length = 0;
        if (snippet.isSqlDialect()) {
          editor.useHueAutocompleter = true;
        } else {
          editor.completers.push(langTools.snippetCompleter);
          editor.completers.push(langTools.textCompleter);
          editor.completers.push(langTools.keyWordCompleter);
          editor.completers.push(snippet.autocompleter);
        }
      }
    };
    var langTools = ace.require('ace/ext/language_tools');
    langTools.textCompleter.setSqlMode(snippet.isSqlDialect());
    initAutocompleters();
    var UNICODES_TO_REMOVE = /[\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u200B\u202F\u205F\u3000\uFEFF]/gi; //taken from https://www.cs.tut.fi/~jkorpela/chars/spaces.html

    var removeUnicodes = function removeUnicodes(value) {
      return value.replace(UNICODES_TO_REMOVE, ' ');
    };
    var placeHolderElement = null;
    var placeHolderVisible = false;
    var placeHolderText = snippet.getPlaceHolder();
    if (placeHolderText) {
      placeHolderElement = $('<div>').text(placeHolderText).css('margin-left', '6px').addClass('ace_invisible ace_emptyMessage');
      if (editor.getValue().length === 0) {
        placeHolderElement.appendTo(editor.renderer.scroller);
        placeHolderVisible = true;
      }
    }
    var pasteListener = editor.on('paste', function (e) {
      e.text = removeUnicodes(e.text);
    });
    disposeFunctions.push(function () {
      editor.off('paste', pasteListener);
    });
    var inputListener = editor.on('input', function () {
      if (editor.getValue().length === 0) {
        if (!placeHolderVisible && placeHolderElement) {
          placeHolderElement.appendTo(editor.renderer.scroller);
          placeHolderVisible = true;
        }
      } else {
        placeHolderElement.remove();
        placeHolderVisible = false;
      }
      if (options.updateOnInput) {
        snippet.statement_raw(removeUnicodes(editor.getValue()));
      }
    });
    disposeFunctions.push(function () {
      editor.off('input', inputListener);
    });
    if (snippet.aceCursorPosition()) {
      editor.moveCursorToPosition(snippet.aceCursorPosition());
      window.setTimeout(function () {
        editor.centerSelection();
      }, 0);
    }
    var focusListener = editor.on('focus', function () {
      initAutocompleters();
      snippet.inFocus(true);
      $('.ace-editor').data('last-active-editor', false);
      $el.data('last-active-editor', true);
      if (editor.session.$backMarkers) {
        for (var marker in editor.session.$backMarkers) {
          if (editor.session.$backMarkers[marker].clazz === 'highlighted') {
            editor.session.removeMarker(editor.session.$backMarkers[marker].id);
          }
        }
      }
    });
    disposeFunctions.push(function () {
      editor.off('focus', focusListener);
    });
    var changeSelectionListener = editor.selection.on('changeSelection', function () {
      snippet.selectedStatement(editor.getSelectedText());
    });
    disposeFunctions.push(function () {
      editor.selection.off('changeSelection', changeSelectionListener);
    });
    var blurListener = editor.on('blur', function () {
      snippet.inFocus(false);
      snippet.statement_raw(removeUnicodes(editor.getValue()));
      if (options.onBlur) {
        options.onBlur($el, removeUnicodes(editor.getValue()));
      }
    });
    disposeFunctions.push(function () {
      editor.off('blur', blurListener);
    });
    editor.previousSize = 0;

    // TODO: Get rid of this
    var idInterval = window.setInterval(function () {
      editor.session.getMode().$id = snippet.getAceMode(); // forces the id again because of Ace command internals
    }, 100);
    disposeFunctions.push(function () {
      window.clearInterval(idInterval);
    });
    editor.middleClick = false;
    var mousedownListener = editor.on('mousedown', function (e) {
      if (e.domEvent.which === 2) {
        // middle click
        editor.middleClick = true;
        var tempText = editor.getSelectedText();
        if (e.$pos) {
          editor.session.insert(e.$pos, tempText);
        }
        window.setTimeout(function () {
          editor.middleClick = false;
          if (e.$pos) {
            editor.moveCursorTo(e.$pos.row, e.$pos.column + tempText.length);
          }
        }, 200);
      }
    });
    disposeFunctions.push(function () {
      editor.off('mousedown', mousedownListener);
    });
    var aceReplaceSub = huePubSub.subscribe('ace.replace', function (data) {
      var Range = ace.require('ace/range').Range;
      var range = new Range(data.location.first_line - 1, data.location.first_column - 1, data.location.last_line - 1, data.location.last_column - 1);
      editor.getSession().getDocument().replace(range, data.text);
    });
    disposeFunctions.push(function () {
      aceReplaceSub.remove();
    });
    var clickListener = editor.on('click', function () {
      editor.clearErrorsAndWarnings();
    });
    disposeFunctions.push(function () {
      editor.off('click', clickListener);
    });
    var changeListener = editor.on('change', function () {
      snippet.statement_raw(removeUnicodes(editor.getValue()));
      editor.session.getMode().$id = snippet.getAceMode();
      var currentSize = editor.session.getLength();
      if (currentSize !== editor.previousSize && currentSize >= editorOptions.minLines && currentSize <= editorOptions.maxLines) {
        editor.previousSize = editor.session.getLength();
        $(document).trigger('editorSizeChanged');
      }
      // automagically change snippet type
      // TODO: Remove completely, check if used in code, '% dialect'
      var firstLine = editor.session.getLine(0);
      if (firstLine.indexOf('%') === 0 && firstLine.charAt(firstLine.length - 1) === ' ') {
        var availableSnippets = snippet.availableSnippets;
        var removeFirstLine = false;
        for (var i = 0; i < availableSnippets.length; i++) {
          if ($.trim(firstLine.substr(1)) === availableSnippets[i].type()) {
            snippet.type(availableSnippets[i].type());
            removeFirstLine = true;
            break;
          }
        }
        if (removeFirstLine) {
          editor.session.remove(new AceRange(0, 0, 0, 200));
        }
      }
    });
    disposeFunctions.push(function () {
      editor.off('change', changeListener);
    });
    editor.commands.addCommand({
      name: 'execute',
      bindKey: {
        win: 'Ctrl-Enter',
        mac: 'Command-Enter|Ctrl-Enter'
      },
      exec: function exec() {
        snippet.statement_raw(removeUnicodes(editor.getValue()));
        snippet.execute();
      }
    });
    editor.commands.addCommand({
      name: 'switchTheme',
      bindKey: {
        win: 'Ctrl-Alt-t',
        mac: 'Command-Alt-t'
      },
      exec: function exec() {
        darkThemeEnabled = !darkThemeEnabled;
        setInLocalStorage('ace.dark.theme.enabled', darkThemeEnabled);
        editor.setTheme(darkThemeEnabled ? 'ace/theme/hue_dark' : 'ace/theme/hue');
      }
    });
    editor.commands.addCommand({
      name: 'new',
      bindKey: {
        win: 'Ctrl-e',
        mac: 'Command-e'
      },
      exec: function exec() {
        huePubSub.publish('editor.create.new');
      }
    });
    editor.commands.addCommand({
      name: 'save',
      bindKey: {
        win: 'Ctrl-s',
        mac: 'Command-s|Ctrl-s'
      },
      exec: function exec() {
        huePubSub.publish('editor.save');
      }
    });
    editor.commands.addCommand({
      name: 'esc',
      bindKey: {
        win: 'Ctrl-Shift-p',
        mac: 'Ctrl-Shift-p|Command-Shift-p'
      },
      exec: function exec() {
        huePubSub.publish('editor.presentation.toggle');
      }
    });
    editor.commands.bindKey('Ctrl-P', 'golineup');
    editor.commands.addCommand({
      name: 'format',
      bindKey: {
        win: 'Ctrl-i|Ctrl-Shift-f|Ctrl-Alt-l',
        mac: 'Command-i|Ctrl-i|Ctrl-Shift-f|Command-Shift-f|Ctrl-Shift-l|Cmd-Shift-l'
      },
      exec: function exec() {
        var formatted_statements = format(editor.getSelectedText() !== '' ? editor.getSelectedText() : editor.getValue(), {
          uppercase: true,
          linesBetweenQueries: 2,
          indentQuerySeparator: true
        });
        if (editor.getSelectedText() !== '') {
          editor.session.replace(editor.session.selection.getRange(), formatted_statements);
        } else {
          editor.setValue(formatted_statements);
          snippet.statement_raw(removeUnicodes(editor.getValue()));
        }
      }
    });
    editor.commands.addCommand({
      name: 'gotolinealternative',
      bindKey: {
        win: 'Ctrl-j',
        mac: 'Command-j|Ctrl-j'
      },
      exec: editor.commands.commands['gotoline'].exec
    });
    var isNewStatement = function isNewStatement() {
      return /^\s*$/.test(editor.getValue()) || /^.*;\s*$/.test(editor.getTextBeforeCursor());
    };
    var insertSqlAtCursor = function insertSqlAtCursor(text, cursorEndAdjust, menu) {
      var before = editor.getTextBeforeCursor();
      if (/\S+$/.test(before)) {
        text = ' ' + text;
      }
      if (menu) {
        menu.hide();
      }
      editor.session.insert(editor.getCursorPosition(), text);
      if (cursorEndAdjust !== 0) {
        var cursor = editor.getCursorPosition();
        editor.moveCursorToPosition({
          row: cursor.row,
          column: cursor.column + cursorEndAdjust
        });
      }
      editor.clearSelection();
      editor.focus();
    };
    var insertTableAtCursorSub = huePubSub.subscribe('editor.insert.table.at.cursor', function (details) {
      if ($el.data('last-active-editor')) {
        var qualifiedName = snippet.database() === details.database ? details.name : details.database + '.' + details.name;
        if (isNewStatement()) {
          insertSqlAtCursor('SELECT * FROM ' + qualifiedName + ' LIMIT 100;', -1);
        } else {
          insertSqlAtCursor(qualifiedName + ' ', 0);
        }
      }
    });
    var insertColumnAtCursorSub = huePubSub.subscribe('editor.insert.column.at.cursor', function (details) {
      if ($el.data('last-active-editor')) {
        if (isNewStatement()) {
          var qualifiedFromName = snippet.database() === details.database ? details.table : details.database + '.' + details.table;
          insertSqlAtCursor('SELECT ' + details.name + ' FROM ' + qualifiedFromName + ' LIMIT 100;', -1);
        } else {
          insertSqlAtCursor(details.name + ' ', 0);
        }
      }
    });
    var insertAtCursorSub = huePubSub.subscribe(INSERT_AT_CURSOR_EVENT, function (details) {
      if (details.targetEditor && details.targetEditor === editor || $el.data('last-active-editor')) {
        insertSqlAtCursor(details.text + ' ', details.cursorEndAdjust || 0);
      }
    });
    disposeFunctions.push(function () {
      insertTableAtCursorSub.remove();
      insertColumnAtCursorSub.remove();
      insertAtCursorSub.remove();
    });
    var dblClickHdfsItemSub = huePubSub.subscribe('assist.dblClickHdfsItem', function (assistHdfsEntry) {
      if ($el.data('last-active-editor')) {
        editor.session.insert(editor.getCursorPosition(), "'" + assistHdfsEntry.path + "'");
      }
    });
    disposeFunctions.push(function () {
      dblClickHdfsItemSub.remove();
    });
    var dblClickAdlsItemSub = huePubSub.subscribe('assist.dblClickAdlsItem', function (assistHdfsEntry) {
      if ($el.data('last-active-editor')) {
        editor.session.insert(editor.getCursorPosition(), 'adl:/' + assistHdfsEntry.path + "'");
      }
    });
    disposeFunctions.push(function () {
      dblClickAdlsItemSub.remove();
    });
    var dblClickAbfsItemSub = huePubSub.subscribe('assist.dblClickAbfsItem', function (assistHdfsEntry) {
      if ($el.data('last-active-editor')) {
        editor.session.insert(editor.getCursorPosition(), 'abfs://' + assistHdfsEntry.path + "'");
      }
    });
    disposeFunctions.push(function () {
      dblClickAbfsItemSub.remove();
    });
    var dblClickOfsItemSub = huePubSub.subscribe('assist.dblClickOfsItem', function (assistOfsEntry) {
      if ($el.data('last-active-editor')) {
        editor.session.insert(editor.getCursorPosition(), 'ofs://' + assistOfsEntry.path + "'");
      }
    });
    disposeFunctions.push(function () {
      dblClickOfsItemSub.remove();
    });
    var dblClickGitItemSub = huePubSub.subscribe('assist.dblClickGitItem', function (assistGitEntry) {
      if ($el.data('last-active-editor')) {
        editor.session.setValue(assistGitEntry.fileContent());
      }
    });
    disposeFunctions.push(function () {
      dblClickGitItemSub.remove();
    });
    var dblClickS3ItemSub = huePubSub.subscribe('assist.dblClickS3Item', function (assistS3Entry) {
      if ($el.data('last-active-editor')) {
        editor.session.insert(editor.getCursorPosition(), "'s3a://" + assistS3Entry.path + "'");
      }
    });
    disposeFunctions.push(function () {
      dblClickS3ItemSub.remove();
    });
    var dblClickGSItemSub = huePubSub.subscribe('assist.dblClickGSItem', function (assistGSEntry) {
      if ($el.data('last-active-editor')) {
        editor.session.insert(editor.getCursorPosition(), "'gs://" + assistGSEntry.path + "'");
      }
    });
    disposeFunctions.push(function () {
      dblClickGSItemSub.remove();
    });
    var sampleErrorInsertSub = huePubSub.subscribe('sample.error.insert.click', function (popoverEntry) {
      var table = popoverEntry.identifierChain[popoverEntry.identifierChain.length - 1]['name'];
      var text = 'SELECT * FROM ' + table + ' LIMIT 100;';
      insertSqlAtCursor(text, -1);
    });
    disposeFunctions.push(function () {
      sampleErrorInsertSub.remove();
    });
    var autocompleteTemporarilyDisabled = false;
    var autocompleteThrottle = -1;
    var afterExecListener = editor.commands.on('afterExec', function (e) {
      if (editor.getOption('enableLiveAutocompletion') && e.command.name === 'insertstring') {
        if (/\S+\(\)$/.test(e.args)) {
          editor.moveCursorTo(editor.getCursorPosition().row, editor.getCursorPosition().column - 1);
          return;
        }
        window.clearTimeout(autocompleteThrottle);
        autocompleteThrottle = window.setTimeout(function () {
          var textBeforeCursor = editor.getTextBeforeCursor();
          var questionMarkMatch = textBeforeCursor.match(/select\s+(\? from \S+[^.]\s*$)/i);
          if (questionMarkMatch && $('.ace_autocomplete:visible').length === 0) {
            editor.moveCursorTo(editor.getCursorPosition().row, editor.getCursorPosition().column - (questionMarkMatch[1].length - 1));
            editor.removeTextBeforeCursor(1);
            huePubSub.publish(REFRESH_STATEMENT_LOCATIONS_EVENT, snippet.id());
            window.setTimeout(function () {
              editor.execCommand('startAutocomplete');
            }, 1);
          } else if (/\.$/.test(textBeforeCursor)) {
            huePubSub.publish(REFRESH_STATEMENT_LOCATIONS_EVENT, snippet.id());
            window.setTimeout(function () {
              editor.execCommand('startAutocomplete');
            }, 1);
          }
        }, 400);
      }
      editor.session.getMode().$id = snippet.getAceMode(); // forces the id again because of Ace command internals
      // if it's pig and before it's LOAD ' we disable the autocomplete and show a filechooser btn
      if (editor.session.getMode().$id === 'ace/mode/pig' && e.args) {
        var textBefore = editor.getTextBeforeCursor();
        if (e.args === "'" && textBefore.toUpperCase().indexOf('LOAD ') > -1 && textBefore.toUpperCase().indexOf('LOAD ') === textBefore.toUpperCase().length - 5 || textBefore.toUpperCase().indexOf("LOAD '") > -1 && textBefore.toUpperCase().indexOf("LOAD '") === textBefore.toUpperCase().length - 6) {
          if (editor.getOption('enableBasicAutocompletion')) {
            editor.disableAutocomplete();
            autocompleteTemporarilyDisabled = true;
          }
          var btn = editor.showFileButton();
          btn.on('click', function (ie) {
            ie.preventDefault();
            // TODO: Turn the ace file chooser into a component and remove css class references
            var $aceFileChooseContent = $('.ace-filechooser-content');
            if (!$aceFileChooseContent.data('jHueFileChooser')) {
              if ($aceFileChooseContent.data('spinner') == null) {
                $aceFileChooseContent.data('spinner', $aceFileChooseContent.html());
              } else {
                $aceFileChooseContent.html($aceFileChooseContent.data('spinner'));
              }
              $aceFileChooseContent.jHueFileChooser({
                onFileChoose: function onFileChoose(filePath) {
                  editor.session.insert(editor.getCursorPosition(), filePath + "'");
                  editor.hideFileButton();
                  if (autocompleteTemporarilyDisabled) {
                    editor.enableAutocomplete();
                    autocompleteTemporarilyDisabled = false;
                  }
                  $('.ace-filechooser').hide();
                },
                selectFolder: false,
                createFolder: false
              });
            }
            $('.ace-filechooser').css({
              top: $(ie.currentTarget).position().top,
              left: $(ie.currentTarget).position().left
            }).show();
          });
        } else {
          editor.hideFileButton();
          if (autocompleteTemporarilyDisabled) {
            editor.enableAutocomplete();
            autocompleteTemporarilyDisabled = false;
          }
        }
        if (e.args !== "'" && textBefore.toUpperCase().indexOf("LOAD '") > -1 && textBefore.toUpperCase().indexOf("LOAD '") === textBefore.toUpperCase().length - 6) {
          editor.hideFileButton();
          if (autocompleteTemporarilyDisabled) {
            editor.enableAutocomplete();
            autocompleteTemporarilyDisabled = false;
          }
        }
      }
    });
    disposeFunctions.push(function () {
      editor.commands.off('afterExec', afterExecListener);
    });
    editor.$blockScrolling = Infinity;
    snippet.ace(editor);
  },
  update: function update(element, valueAccessor) {
    var options = ko.unwrap(valueAccessor());
    var snippet = options.snippet;
    var AceRange = ace.require('ace/range').Range;
    if (snippet.ace()) {
      var editor = snippet.ace();
      if (typeof options.readOnly !== 'undefined') {
        editor.setReadOnly(options.readOnly);
      }
      var range = options.highlightedRange ? options.highlightedRange() : null;
      editor.session.setMode(snippet.getAceMode());
      if (range && JSON.stringify(range.start) !== JSON.stringify(range.end)) {
        var conflictingWithErrorMarkers = false;
        if (editor.session.$backMarkers) {
          for (var marker in editor.session.$backMarkers) {
            if (editor.session.$backMarkers[marker].clazz === 'ace_error-line') {
              var errorRange = editor.session.$backMarkers[marker].range;
              if (range.start.row <= errorRange.end.row && range.end.row >= errorRange.start.row) {
                conflictingWithErrorMarkers = true;
              }
            }
            if (editor.session.$backMarkers[marker].clazz === 'highlighted') {
              editor.session.removeMarker(editor.session.$backMarkers[marker].id);
            }
          }
        }
        if (!conflictingWithErrorMarkers) {
          var lineOffset = snippet.lastAceSelectionRowOffset();
          window.setTimeout(function () {
            editor.session.addMarker(new AceRange(range.start.row + lineOffset, range.start.column, range.end.row + lineOffset, range.end.column), 'highlighted', 'line');
            ace.require('ace/lib/dom').importCssString('.highlighted {\
                  background-color: #E3F7FF;\
                  position: absolute;\
              }');
            editor.scrollToLine(range.start.row + lineOffset, true, true, function () {});
          }, 0);
        }
      }
      try {
        editor._emit('change');
      } catch (e) {}
    }
  }
});