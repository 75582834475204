// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import $ from 'jquery';
import * as ko from 'knockout';
import huePubSub from 'utils/huePubSub';
import { hueLocalStorage } from 'utils/storageUtils';
ko.bindingHandlers.splitDraggable = {
  init: function init(element, valueAccessor) {
    var options = ko.unwrap(valueAccessor());
    var leftPanelWidth = hueLocalStorage(options.appName + '_left_panel_width') != null ? Math.max(hueLocalStorage(options.appName + '_left_panel_width'), 250) : 250;
    var rightPanelWidth = hueLocalStorage(options.appName + '_right_panel_width') != null ? Math.max(hueLocalStorage(options.appName + '_right_panel_width'), 250) : 290;
    var containerSelector = options.containerSelector || '.panel-container';
    var contentPanelSelector = options.contentPanelSelector || '.content-panel';
    var onPosition = options.onPosition || function () {};
    var hasLeftPanel = !!options.leftPanelVisible;
    var hasRightPanel = !!options.rightPanelVisible;
    var isRightPanel = !!options.isRightPanel;
    var $resizer = $(element);
    var $leftPanel = $('.left-panel');
    var $rightPanel = $('.right-panel');
    var $contentPanel = $(contentPanelSelector);
    var $container = $(containerSelector);
    var positionPanels = function positionPanels() {
      if (isRightPanel) {
        var oppositeWidth = hasLeftPanel && ko.unwrap(options.leftPanelVisible) ? $leftPanel.width() + $resizer.width() : 0;
        var totalWidth = $container.width() - oppositeWidth;
        if (ko.unwrap(options.rightPanelVisible) && ko.unwrap(options.rightPanelAvailable)) {
          $resizer.show();
          rightPanelWidth = Math.min(rightPanelWidth, $container.width() - 100);
          var contentPanelWidth = totalWidth - rightPanelWidth - $resizer.width();
          $rightPanel.css('width', rightPanelWidth + 'px');
          $contentPanel.css('width', contentPanelWidth + 'px');
          $resizer.css('left', $container.width() - rightPanelWidth - $resizer.width() + 'px');
          $contentPanel.css('right', rightPanelWidth + $resizer.width() + 'px');
        } else {
          if (oppositeWidth === 0) {
            $contentPanel.css('width', '100%');
          } else {
            $contentPanel.css('width', totalWidth);
          }
          $contentPanel.css('right', '0');
          $resizer.hide();
        }
      } else {
        var _oppositeWidth = hasRightPanel && ko.unwrap(options.rightPanelVisible) && ko.unwrap(options.rightPanelAvailable) ? $rightPanel.width() + $resizer.width() : 0;
        var _totalWidth = $container.width() - _oppositeWidth;
        if (ko.unwrap(options.leftPanelVisible)) {
          $resizer.show();
          leftPanelWidth = Math.min(leftPanelWidth, _totalWidth - 100);
          var _contentPanelWidth = _totalWidth - leftPanelWidth - $resizer.width();
          $leftPanel.css('width', leftPanelWidth + 'px');
          $contentPanel.css('width', _contentPanelWidth + 'px');
          $resizer.css('left', leftPanelWidth + 'px');
          $contentPanel.css('left', leftPanelWidth + $resizer.width() + 'px');
        } else {
          if (_oppositeWidth === 0) {
            $contentPanel.css('width', '100%');
          } else {
            $contentPanel.css('width', _totalWidth);
          }
          $contentPanel.css('left', '0');
          $resizer.hide();
        }
      }
      onPosition();
    };
    if (ko.isObservable(options.leftPanelVisible)) {
      options.leftPanelVisible.subscribe(positionPanels);
    }
    if (ko.isObservable(options.rightPanelVisible)) {
      options.rightPanelVisible.subscribe(positionPanels);
    }
    if (ko.isObservable(options.rightPanelAvailable)) {
      options.rightPanelAvailable.subscribe(positionPanels);
    }
    var dragTimeout = -1;
    $resizer.draggable({
      axis: 'x',
      containment: $container,
      drag: function drag(event, ui) {
        if (isRightPanel) {
          ui.position.left = Math.min($container.width() - 200, ui.position.left);
        } else {
          ui.position.left = Math.min($container.width() - $container.position().left - 200, Math.max(250, ui.position.left));
        }
        window.clearTimeout(dragTimeout);
        dragTimeout = window.setTimeout(function () {
          if (isRightPanel) {
            var oppositeWidth = hasLeftPanel && ko.unwrap(options.leftPanelVisible) ? $leftPanel.width() + $resizer.width() : 0;
            var totalWidth = $container.width() - oppositeWidth;
            rightPanelWidth = $container.width() - ui.position.left;
            $rightPanel.css('width', rightPanelWidth + 'px');
            $contentPanel.css('width', totalWidth - rightPanelWidth + 'px');
            // $contentPanel.css("right", rightPanelWidth + $resizer.width());
          } else {
            var _oppositeWidth2 = hasRightPanel && ko.unwrap(options.rightPanelVisible) ? $rightPanel.width() + $resizer.width() : 0;
            var _totalWidth2 = $container.width() - _oppositeWidth2;
            leftPanelWidth = ui.position.left;
            $leftPanel.css('width', leftPanelWidth + 'px');
            $contentPanel.css('width', _totalWidth2 - leftPanelWidth - $resizer.width() + 'px');
            $contentPanel.css('left', leftPanelWidth + $resizer.width());
          }
          onPosition();
        }, 10);
      },
      stop: function stop() {
        if (isRightPanel) {
          hueLocalStorage(options.appName + '_right_panel_width', rightPanelWidth);
        } else {
          hueLocalStorage(options.appName + '_left_panel_width', leftPanelWidth);
        }
        window.setTimeout(positionPanels, 100);
        huePubSub.publish('split.panel.resized');
      }
    });
    var positionTimeout = -1;
    $(window).resize(function () {
      window.clearTimeout(positionTimeout);
      positionTimeout = window.setTimeout(function () {
        positionPanels();
      }, 1);
    });
    function initialPositioning() {
      if (!$container.is(':visible')) {
        window.setTimeout(initialPositioning, 50);
      } else {
        positionPanels();
        // Even though the container is visible some slow browsers might not
        // have rendered the panels
        window.setTimeout(positionPanels, 100);
      }
    }
    initialPositioning();
  }
};