// Licensed to Cloudera, Inc. under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  Cloudera, Inc. licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import $ from 'jquery';

/*
 * jHue horizontal scrollbar for dataTables_wrapper
 *
 */

var pluginName = 'jHueHorizontalScrollbar',
  defaults = {};
function Plugin(element, options) {
  this.element = element;
  this.options = $.extend({}, defaults, options);
  this._defaults = defaults;
  this._name = pluginName;
  this.init();
}
function initWhenReady(el) {
  var $wrapper = $(el).parents('.dataTables_wrapper');
  if (!$wrapper.length) {
    return;
  }
  var colWidth = $(el).find('thead tr th').outerWidth();
  var wrapperWidth = $wrapper.width();
  var wrapperScrollWidth = $wrapper[0].scrollWidth;
  if ($wrapper.find('.hue-scrollbar-x-rail').length === 0 && wrapperWidth < wrapperScrollWidth) {
    $('.hue-scrollbar-x-rail').remove();
    var $scrollbarRail = $('<div>');
    var $scrollbar = $('<div>').addClass('hue-scrollbar-x');
    $scrollbar.width(Math.max(20, wrapperWidth * (wrapperWidth / wrapperScrollWidth)));
    $scrollbar.appendTo($scrollbarRail);
    try {
      $scrollbar.draggable('destroy');
    } catch (e) {}
    var throttleScrollTimeout = -1;
    var scrollbarWidth = 0;
    var railWidth = 0;
    $scrollbar.draggable({
      axis: 'x',
      containment: 'parent',
      start: function start() {
        $wrapper = $(el).parents('.dataTables_wrapper');
        wrapperScrollWidth = $wrapper[0].scrollWidth;
        wrapperWidth = $wrapper.width();
        scrollbarWidth = $(this).outerWidth();
        railWidth = $scrollbarRail.innerWidth();
        $scrollbarRail.css('opacity', '1');
      },
      drag: function drag(e, ui) {
        $wrapper.scrollLeft((wrapperScrollWidth - wrapperWidth) * (ui.position.left / (railWidth - scrollbarWidth)));
        window.clearTimeout(throttleScrollTimeout);
        throttleScrollTimeout = window.setTimeout(function () {
          $wrapper.trigger('scroll');
        }, 50);
      },
      stop: function stop() {
        $scrollbarRail.css('opacity', '');
      }
    });
    $wrapper.bind('mousewheel', function (e) {
      var _deltaX = e.deltaX * e.deltaFactor,
        _deltaY = e.deltaY;
      if (Math.abs(_deltaX) >= Math.abs(_deltaY)) {
        var self = this;
        self.scrollLeft += _deltaX;
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        if (self.scrollLeft > 0) {
          $scrollbar.css('left', ($scrollbarRail[0].getBoundingClientRect().width - $scrollbar[0].getBoundingClientRect().width) * (self.scrollLeft / (self.scrollWidth - self.getBoundingClientRect().width)) + 'px');
          window.clearTimeout(throttleScrollTimeout);
          throttleScrollTimeout = window.setTimeout(function () {
            $wrapper.trigger('scroll');
          }, 50);
        }
      }
    });
    $scrollbarRail.addClass('hue-scrollbar-x-rail').appendTo($wrapper);
    $scrollbarRail.width(wrapperWidth - colWidth);
    $scrollbarRail.css('marginLeft', colWidth + 'px');
    if ($scrollbarRail.position().top > $(window).height() - 10) {
      $scrollbarRail.css('bottom', '0');
    }
    $wrapper.bind('scroll_update', function () {
      $scrollbar.css('left', ($scrollbarRail.width() - $scrollbar.width()) * ($wrapper.scrollLeft() / (wrapperScrollWidth - wrapperWidth)) + 'px');
    });
  } else {
    if (wrapperWidth === wrapperScrollWidth) {
      $('.hue-scrollbar-x-rail').hide();
    } else {
      $('.hue-scrollbar-x-rail').show();
    }
    $wrapper.find('.hue-scrollbar-x-rail').width(wrapperWidth - colWidth);
    var scrollbar = $wrapper.find('.hue-scrollbar-x');
    scrollbar.width(Math.max(20, wrapperWidth * (wrapperWidth / wrapperScrollWidth)));
    var scrollbarRail = $wrapper.find('.hue-scrollbar-x-rail');
    scrollbarRail.width(wrapperWidth - colWidth);
    scrollbarRail.css('marginLeft', colWidth + 'px');
    scrollbar.css('left', (scrollbarRail.width() - scrollbar.width()) * ($wrapper.scrollLeft() / (wrapperScrollWidth - wrapperWidth)) + 'px');
  }
}
Plugin.prototype.init = function () {
  var el = this.element;
  var checkWidth = function checkWidth() {
    if ($(el).parents('.dataTables_wrapper').width() > 0) {
      initWhenReady(el);
    } else {
      window.setTimeout(checkWidth, 100);
    }
  };
  checkWidth();
};
$.fn[pluginName] = function (options) {
  return this.each(function () {
    $.data(this, 'plugin_' + pluginName, new Plugin(this, options));
  });
};